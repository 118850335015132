@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@font-face {
    font-family: "GreatVibes";
    src: url("../public/greatvibes.ttf");
}

@font-face {
    font-family: "BerkshireSwash";
    src: url("../public/berkshireswash.ttf");
}